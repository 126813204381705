import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchStart, fetchEnd } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import config from '../../config';

const useStyles = makeStyles({
  table: {
    minWidth: 607,
    width: 'unset',
  },
});

export default function Users({ loading, getData, data = [] }) {
  const classes = useStyles();
  const [isShowChangeOwnerModal, setIsShowChangeOwnerModal] = useState(false);
  const [currentOwner, setCurrentOwner] = useState(null);
  const [newOwnerId, setNewOwnerId] = useState('');
  const [newOwnerOptions, setNewOwnerOptions] = useState([]);
  const [isChangingOwner, setIsChangingOwner] = useState(false);
  const [isDisableChangeOwner, setIsDisableChangeOwner] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentOwner) {
      setNewOwnerOptions(
        data.filter((user) => user.status !== 'Pending' && currentOwner.userId !== user.userId),
      );
    }
  }, [data, currentOwner]);

  useEffect(() => {
    if (
      data?.length === 1 ||
      data?.filter((user) => user.status !== 'Pending' && user.role !== 'OWNER').length === 0
    ) {
      setIsDisableChangeOwner(true);
    } else {
      setIsDisableChangeOwner(false);
    }
  }, [data, newOwnerOptions]);

  const handleToggleShowChangeOwnerModal = useCallback(() => {
    setIsShowChangeOwnerModal(!isShowChangeOwnerModal);
  }, [isShowChangeOwnerModal]);

  const handleChangeOwner = async () => {
    setIsChangingOwner(true);
    dispatch(fetchStart());
    fetch(`${config.API_IVY_ADMIN_PANEL}/owners`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        old_owner_id: currentOwner.userId,
        new_owner_id: newOwnerId,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return Promise.resolve();
        } else {
          return Promise.reject(res);
        }
      })
      .then(() => {
        setNewOwnerId('');
        handleToggleShowChangeOwnerModal();
        getData();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsChangingOwner(false);
        dispatch(fetchEnd());
      });
  };

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginTop: '10%',
          marginBottom: '10%',
        }}
      >
        <CircularProgress />
      </div>
    );

  const handleSelectUser = (event) => {
    setNewOwnerId(event.target.value);
  };

  return (
    <>
      <Dialog open={isShowChangeOwnerModal} onClose={handleToggleShowChangeOwnerModal}>
        <DialogTitle id="alert-dialog-title">{'Change the organization owner?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select the owner for this organization:
          </DialogContentText>
          <Select
            style={{ width: '100%', marginTop: 10, marginBottom: 10 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={newOwnerId}
            onChange={handleSelectUser}
          >
            {newOwnerOptions.map((user) => (
              <MenuItem value={user.userId} key={user.userId}>
                {user.fullName}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions style={{ marginTop: 20 }}>
          <Button
            onClick={handleToggleShowChangeOwnerModal}
            color="default"
            variant={'outlined'}
            style={{ textTransform: 'none' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleChangeOwner}
            color="secondary"
            variant={'contained'}
            style={{ marginLeft: 20, width: 150, height: 36, textTransform: 'none' }}
            disabled={isChangingOwner || !newOwnerId}
          >
            {isChangingOwner ? <CircularProgress color={'inherit'} size={20} /> : 'Change owner'}
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Ivy Role</TableCell>
              <TableCell>360 Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((user) => (
              <TableRow key={user.userId}>
                <TableCell>{user.fullName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.newRoles?.join(', ')}</TableCell>
                <TableCell>
                  <Chip
                    label={user.status}
                    variant="outlined"
                    style={{
                      borderColor: user.status === 'Pending' ? 'orange' : 'green',
                      color: user.status === 'Pending' ? 'orange' : 'green',
                    }}
                  />
                </TableCell>
                {user.role === 'OWNER' ? (
                  <TableCell style={{ padding: 0 }}>
                    <Button
                      disabled={isDisableChangeOwner}
                      disableElevation
                      style={{ marginLeft: 10, textTransform: 'none' }}
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setCurrentOwner(user);
                        handleToggleShowChangeOwnerModal();
                      }}
                    >
                      Change Owner
                    </Button>
                  </TableCell>
                ) : (
                  <TableCell />
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
