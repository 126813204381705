import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Box, Button, CircularProgress } from '@material-ui/core';
import DroppableColumn from '../drag/DroppableColumn';
import { fetchEnd, fetchStart } from 'react-admin';
import { fetchApi } from '../../utils/api';
import { useDispatch } from 'react-redux';
import { decamelizeKeys } from 'humps';
import { parseProductsToSave, getUpdatedProducts } from '../../utils/ProductUtils';

const Products = ({
  loading,
  organizationId,
  editingData,
  originalData,
  setEditingData,
  getData,
  action,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isDisableSaveBtn, setIsDisableSaveBtn] = useState(false);
  const dispatch = useDispatch();
  const [draggingSourceColId, setDraggingSourceColId] = useState(null);
  const [draggingProductType, setDraggingProductType] = useState(null);

  useEffect(() => {
    const { updatedLicensedProductTypes, updatedDeactivatedProductTypes } = getUpdatedProducts(
      editingData,
      originalData,
    );
    setIsDisableSaveBtn(
      updatedDeactivatedProductTypes?.length === 0 && updatedLicensedProductTypes?.length === 0,
    );
  }, [editingData, originalData]);

  const saveProducts = useCallback(() => {
    const payload = parseProductsToSave(editingData, originalData);
    dispatch(fetchStart());
    setIsSaving(true);
    fetchApi(`/organizations/${organizationId}/products`, {
      method: 'POST',
      body: JSON.stringify(decamelizeKeys(payload)),
    })
      .then((response) => {
        if (response.ok) {
          return Promise.resolve();
        } else {
          return Promise.reject(response);
        }
      })
      .then(() => {
        getData();
      })
      .catch((error) => {
        error.json().then((error) => {
          console.log(error);
        });
      })
      .finally(() => {
        setIsSaving(false);
        dispatch(fetchEnd());
      });
  }, [editingData, originalData, dispatch, organizationId, getData]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    setDraggingSourceColId(null);
    setDraggingProductType(null);

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = editingData.columns[source.droppableId];
    const finish = editingData.columns[destination.droppableId];

    if (start === finish) {
      const newProductIds = Array.from(start.productTypes);
      newProductIds.splice(source.index, 1);
      newProductIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        productTypes: newProductIds,
      };

      const newState = {
        ...editingData,
        columns: {
          ...editingData.columns,
          [newColumn.id]: newColumn,
        },
      };

      setEditingData(newState);

      return;
    }

    // Moving from one list to another
    const startProductIds = Array.from(start.productTypes);
    startProductIds.splice(source.index, 1);
    const newStart = {
      ...start,
      productTypes: startProductIds,
    };

    const finishProductIds = Array.from(finish.productTypes);
    finishProductIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      productTypes: finishProductIds,
    };

    const newState = {
      ...editingData,
      columns: {
        ...editingData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setEditingData(newState);
  };

  const onDragStart = (start) => {
    setDraggingSourceColId(start.source.droppableId);
    setDraggingProductType(start.draggableId);
  };

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginTop: '10%',
          marginBottom: '10%',
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Box display="flex">
        {editingData.columnOrder.map((columnId) => {
          const column = editingData.columns[columnId];
          const products = column.productTypes.map(
            (productType) => editingData.products[productType],
          );
          let droppableSources = [];
          let isDropDisable = false;
          switch (columnId) {
            case 'licensed':
              droppableSources = ['unlicensed', 'deactivated'];
              isDropDisable = droppableSources.indexOf(draggingSourceColId) === -1;
              break;
            case 'deactivated':
              droppableSources = ['licensed'];
              isDropDisable =
                droppableSources.indexOf(draggingSourceColId) === -1 ||
                editingData.products[draggingProductType]?.id === null;
              break;
            case 'unlicensed':
              droppableSources = [];
              isDropDisable = editingData.products[draggingProductType]?.id !== null;
              break;
            default:
              break;
          }

          if (action === 'CREATE' && columnId === 'deactivated') {
            return null;
          }

          return (
            <DroppableColumn
              key={column.id}
              column={column}
              products={products}
              isDropDisabled={isDropDisable}
            />
          );
        })}
      </Box>
      {action === 'EDIT' && (
        <>
          <Button
            onClick={saveProducts}
            disabled={isSaving || loading || isDisableSaveBtn}
            color="secondary"
            variant="contained"
            style={{ textTransform: 'none', marginTop: 20, marginLeft: 8 }}
          >
            {isSaving ? <CircularProgress size={20} color={'inherit'} /> : 'Save'}
          </Button>
          <Button
            onClick={() => setEditingData(originalData)}
            disabled={isSaving || loading || isDisableSaveBtn}
            color="default"
            variant="contained"
            style={{ textTransform: 'none', marginTop: 20, marginLeft: 15 }}
          >
            Reset
          </Button>
        </>
      )}
    </DragDropContext>
  );
};

export default Products;
