import { camelizeKeys } from 'humps';
import { fetchEnd, fetchStart } from 'ra-core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchApi } from '../utils/api';

const initData = {
  id: 0,
  name: '',
  symbol: '',
  pricingTier: 0,
  type: 'Vendor',
  status: 'Active',
  tags: [],
};

export const useCreateOrUpdateDetails = (organizationId, action) => {
  const [loading, setLoading] = useState(false);
  const [editingDetails, setEditingDetails] = useState(initData);
  const [originalDetails, setOriginalDetails] = useState(initData);
  const dispatch = useDispatch();

  const getDetails = useCallback(() => {
    dispatch(fetchStart());
    setLoading(true);
    fetchApi(`/organizations/${organizationId}/details`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        const camelizeData = camelizeKeys(data);
        setEditingDetails({
          ...camelizeData,
          pricingTier: camelizeData.pricingTier == null ? 0 : camelizeData.pricingTier,
        });
        setOriginalDetails({
          ...camelizeData,
          pricingTier: camelizeData.pricingTier == null ? 0 : camelizeData.pricingTier,
        });
      })
      .catch((error) => {
        if (typeof error.json === 'function') {
          error.json().then((error) => {
            console.log(error);
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  }, [dispatch, organizationId]);

  const getTags = useCallback(() => {
    dispatch(fetchStart());
    setLoading(true);
    fetchApi(`/tags?limit=1000`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        setEditingDetails((prevState) => ({
          ...prevState,
          tags: camelizeKeys(data.data),
        }));
      })
      .catch((error) => {
        if (typeof error.json === 'function') {
          error.json().then((error) => {
            console.log(error);
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  }, [dispatch]);

  useEffect(() => {
    if (action === 'EDIT') {
      getDetails();
    }
    if (action === 'CREATE' || action === undefined) {
      getTags();
    }
  }, [getDetails, getTags, action]);

  return {
    loading,
    getDetails,
    editingDetails,
    originalDetails,
    setEditingDetails,
    setOriginalDetails,
    getTags,
  };
};
