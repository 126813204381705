import React from 'react';
import { Box, makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    width: '40%',
    '& > div:not(:first-child)': {
      marginTop: 30,
    },
  },
});

const OwnerInfo = ({ emailAddress, setEmailAddress, fullName, setFullName }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" className={classes.container}>
      <TextField
        label="Email*"
        variant="outlined"
        value={emailAddress}
        onChange={(event) => setEmailAddress(event.target.value)}
      />
      <TextField
        label="Full name*"
        variant="outlined"
        value={fullName}
        onChange={(event) => setFullName(event.target.value)}
      />
    </Box>
  );
};
export default OwnerInfo;
