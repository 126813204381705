import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchStart, fetchEnd } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton, TextField, Checkbox, CircularProgress, Box } from '@material-ui/core';
import { fetchApi } from '../../utils/api';
import { decamelizeKeys } from 'humps';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import * as _ from 'lodash';

const useStyles = makeStyles({
  table: {
    minWidth: 607,
    width: 'unset',
  },
  tableRow: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
      '& .MuiTableCell-sizeSmall:last-child': {
        '& button': {
          display: 'table-cell',
        },
      },
    },
  },
  actionButton: {
    display: 'none',
  },
});

export function ConfigInputTableCell({
  originalPremiumFeature,
  isEditing,
  editingPremiumFeature,
  updateEditingPremiumFeatures,
}) {
  return (
    <TableCell>
      <TextField
        InputProps={{
          readOnly: !isEditing,
        }}
        label={'Limit'}
        variant="outlined"
        size="small"
        type="number"
        disabled={!isEditing}
        value={
          isEditing
            ? editingPremiumFeature?.configurations?.lIMIT || ''
            : originalPremiumFeature?.configurations?.lIMIT || ''
        }
        onChange={(event) => {
          updateEditingPremiumFeatures({
            ...editingPremiumFeature,
            configurations: {
              lIMIT: event.target.value,
            },
          });
        }}
      />
    </TableCell>
  );
}

const MemoizedConfigInputTableCell = React.memo(ConfigInputTableCell);

export default function PremiumFeatures({
  organizationId,
  loading,
  originalData,
  getData,
  editingDataMap,
  setEditingDataMap,
  action,
}) {
  const classes = useStyles();
  const [isSavingPremiumFeature, setIsSavingPremiumFeature] = useState(false);
  const dispatch = useDispatch();

  const updateEditingPremiumFeatures = useCallback(
    (data) => {
      setEditingDataMap((prevState) => ({
        ...prevState,
        [data.featureId]: {
          ...data,
        },
      }));
    },
    [setEditingDataMap],
  );

  const removeEditingPremiumFeatures = (featureId) => {
    setEditingDataMap((prevState) => {
      delete prevState[featureId];
      return {
        ...prevState,
      };
    });
  };

  const handleSavePremiumFeature = (originalFeatureId) => {
    dispatch(fetchStart());
    setIsSavingPremiumFeature(true);
    const payload = decamelizeKeys(editingDataMap[originalFeatureId]);
    if (payload.configurations) {
      delete payload.configurations.l_i_m_i_t;
      payload.configurations.LIMIT = editingDataMap[originalFeatureId].configurations.lIMIT;
    }

    fetchApi(`/organizations/${organizationId}/features`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          return Promise.resolve();
        } else {
          return Promise.reject(response);
        }
      })
      .then(() => {
        getData();
        removeEditingPremiumFeatures(originalFeatureId);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsSavingPremiumFeature(false);
        dispatch(fetchEnd());
      });
  };

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginTop: '10%',
          marginBottom: '10%',
        }}
      >
        <CircularProgress />
      </div>
    );
  return (
    <TableContainer>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Configurations</TableCell>
            <TableCell style={{ width: 50 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {originalData?.map((originalPremiumFeature) => {
            const {
              featureId: originalFeatureId,
              status: originStatus,
              featureName: originFeatureName,
            } = originalPremiumFeature;
            return (
              <TableRow key={originalFeatureId} className={classes.tableRow}>
                <TableCell>
                  <Checkbox
                    onChange={(event, checked) => {
                      if (!_.isEmpty(editingDataMap[originalFeatureId])) {
                        updateEditingPremiumFeatures({
                          ...editingDataMap[originalFeatureId],
                          status: checked,
                          configurations: checked
                            ? editingDataMap[originalFeatureId].configurations
                            : {},
                        });
                      } else {
                        updateEditingPremiumFeatures({
                          ...originalPremiumFeature,
                          status: checked,
                          configurations: checked ? originalPremiumFeature.configurations : {},
                        });
                      }
                    }}
                    checked={
                      editingDataMap[originalFeatureId]?.featureId === originalFeatureId &&
                      editingDataMap[originalFeatureId]?.status != null
                        ? editingDataMap[originalFeatureId]?.status
                        : originStatus
                    }
                    disabled={
                      action === 'EDIT' &&
                      editingDataMap[originalFeatureId]?.featureId !== originalFeatureId
                    }
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </TableCell>
                <TableCell>{originFeatureName}</TableCell>
                {originalPremiumFeature.enableConfigurations ? (
                  <MemoizedConfigInputTableCell
                    originalPremiumFeature={originalPremiumFeature}
                    isEditing={editingDataMap[originalFeatureId]?.featureId === originalFeatureId}
                    updateEditingPremiumFeatures={updateEditingPremiumFeatures}
                    editingPremiumFeature={editingDataMap[originalFeatureId]}
                  />
                ) : (
                  <TableCell />
                )}
                {action === 'EDIT' ? (
                  editingDataMap[originalFeatureId]?.featureId === originalFeatureId ? (
                    isSavingPremiumFeature ? (
                      <TableCell>
                        <CircularProgress color="secondary" size={20} />
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Box display="flex">
                          <IconButton
                            color="secondary"
                            size="small"
                            onClick={() => handleSavePremiumFeature(originalFeatureId)}
                          >
                            <SaveIcon />
                          </IconButton>
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => removeEditingPremiumFeatures(originalFeatureId)}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    )
                  ) : (
                    <TableCell>
                      <IconButton
                        color="secondary"
                        size="small"
                        className={classes.actionButton}
                        onClick={() =>
                          setEditingDataMap((prevState) => ({
                            ...prevState,
                            [originalFeatureId]: originalPremiumFeature,
                          }))
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  )
                ) : null}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
