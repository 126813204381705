export const validateInviteOrgReqParams = (payload) => {
  const { organizationDetail, ownerDetail } = payload;
  const shouldNotEmptyField = [];
  let invalidOrgDetailSymbol = false;
  let invalidEmailAddress = false;
  if (organizationDetail.name.trim() === '') {
    shouldNotEmptyField.push('org detail name');
  }

  if (organizationDetail.symbol.trim() === '') {
    shouldNotEmptyField.push('org detail symbol');
  } else if (organizationDetail.symbol.length !== 3) {
    invalidOrgDetailSymbol = true;
  }

  if (ownerDetail.emailAddress.trim() === '') {
    shouldNotEmptyField.push('owner detail email address');
  } else if (!validateEmail(ownerDetail.emailAddress)) {
    invalidEmailAddress = true;
  }

  if (ownerDetail.fullName.trim() === '') {
    shouldNotEmptyField.push('owner detail full name');
  }

  let errorMessage = '';
  if (shouldNotEmptyField.length > 0) {
    errorMessage = shouldNotEmptyField.join(', ');
    errorMessage = errorMessage + ' must not be empty.';
  }

  if (invalidOrgDetailSymbol) {
    errorMessage = errorMessage
      ? errorMessage + ' Org detail symbol must be 3 characters.'
      : 'Org detail symbol must be 3 characters.';
  }

  if (invalidEmailAddress) {
    errorMessage = errorMessage
      ? errorMessage + ' Owner email is not valid.'
      : 'Owner email is not valid.';
  }

  return (errorMessage && errorMessage[0].toUpperCase() + errorMessage.slice(1)) || '';
};

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
