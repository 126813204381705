import { Button, CircularProgress, makeStyles, TextField as MTextField } from '@material-ui/core';
import { SelectColumnsButton, useSelectedColumns } from '@react-admin/ra-preferences';
import { decamelizeKeys } from 'humps';
import jsonExport from 'jsonexport/dist';
import { fetchEnd, fetchStart } from 'ra-core';
import React, { cloneElement, useState } from 'react';
import {
  ArrayField,
  AutocompleteInput,
  ChipField,
  Datagrid,
  DateField,
  downloadCSV,
  Edit,
  ExportButton,
  Filter,
  List,
  ListButton,
  ReferenceField,
  ReferenceInput,
  sanitizeListRestProps,
  SelectInput,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import Tags from './components/Tags';
import { TagType } from './enum/tag-type';
import { useGet360Roles } from './hooks/useGet360Roles';
import { useGetUserTags } from './hooks/useGetTags';
import { fetchApi } from './utils/api';

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name,emailaddress" label="Search by name or email" alwaysOn />
    <TextInput source="id" label="Search by ID" alwaysOn />
    <SelectInput
      source="status"
      label="Status"
      choices={[
        { id: 'Pending', name: 'Pending' },
        { id: 'Active', name: 'Active' },
      ]}
    />
    <ReferenceInput
      label="Organization"
      source="organizationid"
      reference="organization"
      sort={{ field: 'id', order: 'DESC' }}
      filterToQuery={(q) => ({ name: `${q}` })}
    >
      <AutocompleteInput optionText={({ id, name }) => `${id} - ${name}`} />
    </ReferenceInput>
  </Filter>
);

const UserListColumns = {
  id: <TextField source="id" label="Id" />,
  emailaddress: <TextField source="emailaddress" label="Email" />,
  name: <TextField source="fullname" label="Full name" />,
  roles: (
    <ArrayField source="roles">
      <SingleFieldList>
        <ChipField source="iwa_role.name" clickable={false} />
      </SingleFieldList>
    </ArrayField>
  ),
  status: <ChipField source="status" />,
  tags: (
    <ArrayField source="user_tags" label="Tags" fieldKey="tag.id">
      <SingleFieldList>
        <ChipField source="tag.value" />
      </SingleFieldList>
    </ArrayField>
  ),
  organization: (
    <ReferenceField label="Org Name" source="organizationid" reference="organization">
      <TextField source="name" />
    </ReferenceField>
  ),
  created_date: <DateField source="created_date" label="Created At" locales="de" />,
};

const UserListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, showFilter } = useListContext();

  const handleExport = (users) => {
    const exportUsers = [];
    for (const user of users) {
      const tags = user.organization?.organization_tags.map((tag) => tag.tag.value).join(', ');
      exportUsers.push({
        Email: user.emailaddress,
        Name: user.fullname,
        Role: user.roles.map((role) => role.iwa_role.name),
        Status: user.status,
        'Organization name': user.organization?.name,
        'Organization type': user.organization?.organizationtype,
        'Organization tags': tags,
        Department: user.department,
        Phone: user.phone,
        'User tags': user.user_tags?.map((tag) => tag.tag.value).join(', '),
        'Created date': user.created_date,
      });
    }
    jsonExport(exportUsers, {}, (err, csv) => downloadCSV(csv, 'epilot_users'));
  };

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      <SelectColumnsButton preference="User.list.columns" columns={UserListColumns} />
      <ExportButton
        disabled={props.total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={props.total}
        exporter={(users) => handleExport(users)}
      />
    </TopToolbar>
  );
};

export const UserList = (props) => {
  const columns = useSelectedColumns({
    preferences: 'User.list.columns',
    columns: UserListColumns,
  });
  return (
    <List
      actions={<UserListActions />}
      sort={{ field: 'id', order: 'DESC' }}
      perPage={25}
      filters={<UserFilter />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid rowClick="edit">{columns}</Datagrid>
    </List>
  );
};

const UserTitle = ({ record }) => {
  return <span>User: {record && record.name}</span>;
};

const UserEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label="Back" />
  </TopToolbar>
);

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    width: '50%',
    '& > div:not(:first-child)': {
      marginTop: 30,
    },
  },
  marginBottom: {
    marginBottom: 10,
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'currentColor',
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
});
export const UserEdit = (props) => {
  const classes = useStyles();
  const [isDisableSaveBtn, setIsDisableSaveButton] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { loading, tags, setTags, getTags } = useGetUserTags(props.id);
  const { loading: roleLoading, newRoles } = useGet360Roles(props.id);

  const dispatch = useDispatch();

  const saveTags = () => {
    dispatch(fetchStart());
    setIsSaving(true);
    fetchApi(`/users/${props.id}/tags`, {
      method: 'POST',
      body: JSON.stringify(decamelizeKeys(tags.filter((t) => t.id || t.isAdded))),
    })
      .then((response) => {
        if (response.ok) {
          return Promise.resolve();
        } else {
          return Promise.reject(response);
        }
      })
      .then(() => {
        setIsDisableSaveButton(true);
      })
      .catch((error) => {
        if (typeof error.json === 'function') {
          error.json().then((error) => {
            console.log(error);
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setIsSaving(false);
        dispatch(fetchEnd());
      });
  };

  return (
    <Edit title={<UserTitle />} actions={<UserEditActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="User Details">
          <TextField readonly disabled source="id" />
          <TextField readonly disabled source="status" />
          <TextField source="fullname" label="Name" />
          <TextField source="emailaddress" label="Email" />
          <TextField source="organizationid" label="Org ID" />
          <TextField source="phone" label="Phone" />
          <ArrayField source="roles" label="Ivy Role">
            <SingleFieldList>
              <ChipField source="iwa_role.name" clickable={false} />
            </SingleFieldList>
          </ArrayField>
          {!roleLoading && newRoles?.length > 0 && (
            <MTextField
              disabled
              id="outlined-disabled"
              label="360 Roles"
              defaultValue={newRoles.length ? newRoles.join(', ') : ''}
              className={classes.disabledInput}
              margin="normal"
            />
          )}
          <TextField source="phone" label="Phone" />
          <TextField source="preferred_language.languagecode" label="Language" />
          <DateField source="created_date" label="Created At" className={classes.marginBottom} />
          {tags && (
            <Tags
              style={{ marginTop: 10, marginBottom: 10, width: '50%' }}
              loading={loading}
              tagType={TagType.USER_TAG}
              tagList={tags || []}
              setTags={setTags}
              getTags={getTags}
              setIsDisableSaveButton={setIsDisableSaveButton}
            ></Tags>
          )}
          <Button
            onClick={saveTags}
            disabled={isSaving || loading || isDisableSaveBtn}
            color="secondary"
            variant="contained"
            style={{ textTransform: 'none', marginTop: 10, marginBottom: 10 }}
          >
            {isSaving ? <CircularProgress size={20} color={'inherit'} /> : 'Save'}
          </Button>
        </Tab>
      </TabbedShowLayout>
    </Edit>
  );
};
