import { useState, useEffect } from 'react';
import { Loading, Resource } from 'react-admin';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fakeDataProvider from 'ra-data-fakerest';
import UserIcon from '@material-ui/icons/Person';
import OrganizationIcon from '@material-ui/icons/Business';
import PricingTierIcon from '@material-ui/icons/BusinessCenter';
import gql from 'graphql-tag';
import { OrganizationCreate, OrganizationEdit, OrganizationList } from './Organization';
import { UserEdit, UserList } from './User';
import config from './config';

import buildHasuraProvider, { buildFields } from 'ra-data-hasura';
import { getToken } from './utils/api';
import { PricingTierCreate, PricingTierEdit, PricingTierList } from './PricingTier';

const httpLink = createHttpLink({ uri: config.API_HASURA_GRAPHQL });

const authLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    authorization: `Bearer ${await getToken()}`,
  },
}));

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const emptyDataProvider = fakeDataProvider();

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst) => {
  return queryAst.definitions[0].selectionSet.selections;
};

const GET_PRODUCTS_AND_PREMIUM_FEATURES = gql`
  {
    id
    organizationtype
    emailladdress
    organizationsymbol
    organization_features {
      feature_id
    }
    name
    products {
      id
      productfee
    }
    pricingtier
    pricing_tier_v2
    pricing_tier {
      id
      name
    }
    status
    createdate
    deactivated_date
    activationdate
    organization_tags {
      id
      tag {
        id
        value
      }
    }
  }
`;

const GET_USERS_AND_ORGANIZATIONS = gql`
  {
    organization {
      id
      name
      organizationtype
      organization_tags {
        tag {
          value
        }
      }
    }
    roles(
      order_by: { roleid: asc }
      where: { iwa_role: { _not: { name: { _like: "Everybody" } } } }
    ) {
      iwa_role {
        name
      }
    }
    properties {
      propertyname
      propertyvalue
    }
    phone
    department
    emailaddress
    created_date
    preferred_language {
      languagecode
    }
    fullname
    user_tags {
      tag {
        id
        value
      }
    }
  }
`;

const GET_PRICING_TIERS = gql`
  {
    id
    name
    setting
    created_by
    created_date
    updated_by
    updated_date
    organizations_aggregate {
      aggregate {
        count
      }
    }
  }
`;

const customBuildFields = (type, fetchType) => {
  const resourceName = type.name;

  console.log('resourceName-fetchType: ', resourceName, fetchType);
  const defaultFields = buildFields(type, fetchType);

  if (resourceName === 'organization' && (fetchType === 'GET_LIST' || fetchType === 'GET_ONE')) {
    return extractFieldsFromQuery(GET_PRODUCTS_AND_PREMIUM_FEATURES);
  }

  if (resourceName === 'users' && (fetchType === 'GET_LIST' || fetchType === 'GET_ONE')) {
    const fields = [...defaultFields, ...extractFieldsFromQuery(GET_USERS_AND_ORGANIZATIONS)];
    const omitFields = ['userpassword'];
    return fields.filter((f) => !omitFields.includes(f?.name?.value));
  }

  if (resourceName === 'pricing_tier' && (fetchType === 'GET_LIST' || fetchType === 'GET_ONE')) {
    return extractFieldsFromQuery(GET_PRICING_TIERS);
  }

  // No custom query defined, so use the default query fields (all, but no related/nested).
  return defaultFields;
};

export const useHasuraProvider = () => {
  const [hasuraProvider, setHasuraProvider] = useState(null);

  useEffect(() => {
    (async () => {
      const provider = await buildHasuraProvider({ client }, { buildFields: customBuildFields });
      setHasuraProvider(() => provider);
    })();
  }, []);

  return {
    dataProvider: hasuraProvider || emptyDataProvider,
    client,
    resources: hasuraProvider
      ? [
          <Resource
            key="organization"
            name="organization"
            list={OrganizationList}
            icon={OrganizationIcon}
            create={OrganizationCreate}
            edit={OrganizationEdit}
            options={{ label: 'Organizations' }}
          />,
          <Resource
            key="users"
            name="users"
            list={UserList}
            icon={UserIcon}
            edit={UserEdit}
            options={{ label: 'Users' }}
          />,
          <Resource key="organization_feature" name="organization_feature" />,
          <Resource key="product" name="product" />,
          <Resource key="organization_users" name="organization_users" />,
          <Resource key="userorganization" name="userorganization" />,
          <Resource
            key="pricing_tier"
            name="pricing_tier"
            list={PricingTierList}
            icon={PricingTierIcon}
            edit={PricingTierEdit}
            create={PricingTierCreate}
            options={{ label: 'Pricing tiers' }}
          />,
        ]
      : [
          <Resource key="organization" name="organization" />,
          <Resource key="users" name="users" />,
          <Resource key="pricing_tier" name="pricing_tier" />,
          <Loading key="loader" className="pre-loader" />,
        ],
  };
};
