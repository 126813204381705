import { Auth } from 'aws-amplify';

const authProvider = {
  login: () => Auth.federatedSignIn(),
  checkAuth: () => Auth.currentSession(),
  checkError: () => Promise.resolve(),
  logout: () => Auth.signOut({ global: true }).then(() => window.location.reload()),
  getIdentity: async () => {
    const userInfo = await Auth.currentUserInfo();
    return { id: userInfo.id, fullName: userInfo?.attributes?.email };
  },
  getPermissions: () => Promise.resolve({}),
};

export default authProvider;
