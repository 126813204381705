import { RowForm } from '@react-admin/ra-editable-datagrid';
import { SelectColumnsButton, useSelectedColumns } from '@react-admin/ra-preferences';
import React, { cloneElement, useState } from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  DateField,
  TopToolbar,
  ListButton,
  Edit,
  TabbedShowLayout,
  Tab,
  SelectInput,
  ChipField,
  sanitizeListRestProps,
  ExportButton,
  useListContext,
  CreateButton,
  Create,
  fetchEnd,
  fetchStart,
  ReferenceInput,
  AutocompleteInput,
  SingleFieldList,
  ArrayField,
} from 'react-admin';
import Users from './components/organization/Users';
import PremiumFeatures from './components/organization/PremiumFeatures';
import Products from './components/organization/Products';
import { makeStyles } from '@material-ui/core/styles';
import { useCreateOrUpdateProducts } from './hooks/useCreateOrUpdateProducts';
import { useCreateOrUpdatePremiumFeatures } from './hooks/useCreateOrUpdatePremiumFeatures';
import { useGetUsers } from './hooks/useGetUsers';
import Details from './components/organization/Details';
import { useCreateOrUpdateDetails } from './hooks/useCreateOrUpdateDetails';
import { Box, Button, CircularProgress, Divider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { parseProductsToSave } from './utils/ProductUtils';
import OwnerInfo from './components/organization/OwnerInfo';
import { useCreateOwnerInfo } from './hooks/useCreateOwnerInfo';
import { decamelizeKeys } from 'humps';
import { correctPremiumFeatureReqPayload } from './utils/PremiumFeatureUtils';
import { fetchApi } from './utils/api';
import { useDispatch } from 'react-redux';
import { validateInviteOrgReqParams } from './utils/ValidateUtils';
import { useHistory } from 'react-router-dom';
import { useGetPricingTiers } from './hooks/useCreateOrUpdatePricingTier';
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const OrganizationFilter = (props) => (
  <Filter {...props}>
    <TextInput source="organizationsymbol,name" label="Search by Name or Symbol" alwaysOn />
    <TextInput source="id" label="Search by Id" alwaysOn/>
    <TextInput source="organizationsymbol" label="Symbol" />
    <TextInput source="name" label="Name" />
    <TextInput source="organizationtype" label="Type" />
    <ReferenceInput
      label="Pricing tier V2"
      source="pricing_tier_id"
      reference="pricing_tier"
      sort={{ field: 'id', order: 'DESC' }}
      filterToQuery={(q) => ({ name: `${q}` })}
    >
      <AutocompleteInput optionText={({ name }) => `${name}`} />
    </ReferenceInput>
    <TextInput source="emailladdress" label="Email" />
  </Filter>
);

const OrganizationListColumns = {
  id: <TextField source="id" />,
  symbol: <TextField source="organizationsymbol" label="Symbol" />,
  name: <TextField source="name" />,
  type: <TextField source="organizationtype" label="Type" />,
  pricingtierv2: <TextField source="pricing_tier.name" label="Pricing Tier V2" />,
  emailladdress: <TextField source="emailladdress" label="Email" />,
  status: <ChipField source="status" />,
  tags: (
    <ArrayField source="organization_tags" label="Tags" fieldKey="id">
      <SingleFieldList>
        <ChipField source="tag.value" />
      </SingleFieldList>
    </ArrayField>
  ),
  activationdate: <DateField source="activationdate" locales="de" label="Activation Date" />,
};

const OrganizationListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, showFilter } = useListContext();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false)

  const exportOrganizations  = (orgs) => {
    dispatch(fetchStart());
    setIsLoading(true)
    fetchApi(`/organizations/data-point`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        let merged = [];
        for(let i=0; i<orgs.length; i++) {
          merged.push({
          ...orgs[i], 
          ...(data?.find((item) => item.id === orgs[i].id))}
          );
        }
        jsonExport(
          merged.map((fullOrg) => {
            const actualUsers = fullOrg.actual_user > 0 ? fullOrg.actual_user : '0'
            const maxUsers = fullOrg.max_user > 0 ? fullOrg.max_user : '0'
            
            return { 
              'Id': fullOrg.id,
              'Organization type': fullOrg.organizationtype,
              'Organization symbol': fullOrg.organizationsymbol,
              'Name': fullOrg.name,
              'Pricing tier': fullOrg.pricing_tier?.name,
              'Status': fullOrg.status,
              'Organization tags': fullOrg.organization_tags?.map((tag) => tag.tag.value).join(', '),
              'Actual users': actualUsers,
              'Max users last month': maxUsers,
              'Actual billable users': fullOrg.actual_non_billable_user > 0 ? actualUsers - fullOrg.actual_non_billable_user: actualUsers,
              'Max billable users last month': fullOrg.max_non_billable_user > 0 ? maxUsers - fullOrg.max_non_billable_user: maxUsers,
              'Actual customers': fullOrg.actual_customer > 0 ? fullOrg.actual_customer : '0',
              'Max customers last month': fullOrg.max_customer > 0 ? fullOrg.max_customer : '0',
              'Created date': fullOrg.createdate
           };
          }),
          {},
          (err, csv) => downloadCSV(csv, 'organizations'),
        );
      })
      .catch((error) => {
        if (typeof error.json === 'function') {
          error.json().then((error) => {
            console.log(error);
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setIsLoading(false)
        dispatch(fetchEnd());
      });
  }

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      <SelectColumnsButton
        preference="organization.list.columns"
        columns={OrganizationListColumns}
      />
      <ExportButton
        disabled={props.total === 0 || isLoading}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={props.total}
        exporter={(orgs) => exportOrganizations(orgs)}
      />
      <CreateButton />
    </TopToolbar>
  );
};

export const OrganizationList = (props) => {
  const columns = useSelectedColumns({
    preferences: 'organization.list.columns',
    columns: OrganizationListColumns,
    omit: ['nb_views'],
  });
  return (
    <List
      actions={<OrganizationListActions />}
      sort={{ field: 'id', order: 'DESC' }}
      perPage={25}
      filters={<OrganizationFilter />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid rowClick="edit">{columns}</Datagrid>
    </List>
  );
};

const OrganizationTitle = ({ record, details }) => {
  return <span>Organization: {details.name ? details.name : record?.name}</span>;
};

const OrganizationCreateTitle = () => {
  return <span>Create Organization</span>;
};

const OrganizationAddEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label="Back" />
  </TopToolbar>
);

export const OrganizationCreate = (props) => {
  const {
    productsData,
    setProductsData,
    loading: loadingProducts,
    originalProductsData,
    getProducts,
  } = useCreateOrUpdateProducts(0);

  const {
    loading: loadingPremiumFeatures,
    premiumFeatures,
    getPremiumFeatures,
    editingPremiumFeatures,
    setEditingPremiumFeatures,
  } = useCreateOrUpdatePremiumFeatures(0);

  const {
    loading: loadingDetails,
    getDetails,
    editingDetails,
    originalDetails,
    setEditingDetails,
    setOriginalDetails,
    getTags,
  } = useCreateOrUpdateDetails(props.id);

  const { emailAddress, setEmailAddress, fullName, setFullName } = useCreateOwnerInfo();

  const { pricingTiers } = useGetPricingTiers();

  const productProps = {
    productsData,
    setProductsData,
    loadingProducts,
    originalProductsData,
    getProducts,
  };

  const premiumFeatureProps = {
    loadingPremiumFeatures,
    premiumFeatures,
    getPremiumFeatures,
    editingPremiumFeatures,
    setEditingPremiumFeatures,
  };

  const detailProps = {
    loadingDetails,
    editingDetails,
    originalDetails,
    setEditingDetails,
    setOriginalDetails,
    getDetails,
    pricingTiers,
    getTags,
  };

  const ownerInfoProps = {
    emailAddress,
    setEmailAddress,
    fullName,
    setFullName,
  };

  return (
    <Create title={<OrganizationCreateTitle />} actions={<OrganizationAddEditActions />} {...props}>
      <OrganizationAddEdit
        productProps={productProps}
        premiumFeatureProps={premiumFeatureProps}
        detailProps={detailProps}
        ownerInfoProps={ownerInfoProps}
        action="CREATE"
        {...props}
      />
    </Create>
  );
};

export const OrganizationEdit = (props) => {
  const {
    productsData,
    setProductsData,
    loading: loadingProducts,
    originalProductsData,
    getProducts,
  } = useCreateOrUpdateProducts(props.id);

  const {
    loading: loadingPremiumFeatures,
    premiumFeatures,
    getPremiumFeatures,
    editingPremiumFeatures,
    setEditingPremiumFeatures,
  } = useCreateOrUpdatePremiumFeatures(props.id);

  const { loading: loadingUsers, organizationUsers, getOrganizationUsers } = useGetUsers(props.id);

  const {
    loading: loadingDetails,
    getDetails,
    editingDetails,
    originalDetails,
    setEditingDetails,
    setOriginalDetails,
  } = useCreateOrUpdateDetails(props.id, 'EDIT');

  const { pricingTiers } = useGetPricingTiers();

  const productProps = {
    productsData,
    setProductsData,
    loadingProducts,
    originalProductsData,
    getProducts,
  };

  const premiumFeatureProps = {
    loadingPremiumFeatures,
    premiumFeatures,
    getPremiumFeatures,
    editingPremiumFeatures,
    setEditingPremiumFeatures,
  };

  const userProps = {
    loadingUsers,
    organizationUsers,
    getOrganizationUsers,
  };

  const detailProps = {
    loadingDetails,
    editingDetails,
    originalDetails,
    setEditingDetails,
    setOriginalDetails,
    getDetails,
    pricingTiers,
  };

  return (
    <Edit
      title={<OrganizationTitle details={originalDetails} />}
      actions={<OrganizationAddEditActions />}
      {...props}
    >
      <OrganizationAddEdit
        action="EDIT"
        productProps={productProps}
        premiumFeatureProps={premiumFeatureProps}
        userProps={userProps}
        detailProps={detailProps}
        {...props}
      />
    </Edit>
  );
};

export const ProductForm = (props) => {
  return (
    <RowForm {...props}>
      <TextField source="producttype" label="Product type" />
      <SelectInput
        source="status"
        label="Status"
        choices={[
          { id: 0, name: 'Licensed' },
          { id: 1, name: 'Deactivated' },
          { id: 2, name: 'Unlicensed' },
        ]}
      />
    </RowForm>
  );
};

const useStyles = makeStyles({
  tabContainer: {
    minHeight: 700,
    paddingBottom: 30,
  },
});

const OrganizationAddEdit = ({
  action,
  detailProps,
  premiumFeatureProps,
  userProps = {},
  ownerInfoProps = {},
  productProps,
  id,
  ...restProps
}) => {
  const {
    loadingDetails,
    editingDetails,
    originalDetails,
    setEditingDetails,
    getDetails,
    pricingTiers,
    getTags,
  } = detailProps;
  const { productsData, originalProductsData, setProductsData, getProducts, loadingProducts } =
    productProps;
  const {
    premiumFeatures,
    getPremiumFeatures,
    loadingPremiumFeatures,
    editingPremiumFeatures,
    setEditingPremiumFeatures,
  } = premiumFeatureProps;
  const { organizationUsers, loadingUsers, getOrganizationUsers } = userProps;
  const { emailAddress, setEmailAddress, fullName, setFullName } = ownerInfoProps;
  const [isInvitingOrg, setIsInvitingOrg] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();
  const { organizationtype } = restProps.record;
  const history = useHistory();

  const inviteOrganizationHandler = () => {
    setErrorMessage('');
    const tags = [editingDetails.tags].flat().filter((tag) => tag && tag.isAdded === true);
    editingDetails.tags = tags;
    let payload = {
      organizationDetail: editingDetails,
      ownerDetail: {
        fullName,
        emailAddress,
      },
      products: parseProductsToSave(productsData, originalProductsData),
      organizationFeatures: Object.values(editingPremiumFeatures),
    };
    const errorMessage = validateInviteOrgReqParams(payload);
    if (errorMessage !== '') {
      setErrorMessage(errorMessage);
      return;
    }
    payload = decamelizeKeys(payload);
    payload.organization_features = correctPremiumFeatureReqPayload(payload.organization_features);
    setIsInvitingOrg(true);
    dispatch(fetchStart());
    fetchApi(`/organizations`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          return Promise.resolve();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        history.push('/organization');
      })
      .catch((error) => {
        if (typeof error.json === 'function') {
          error.json().then((error) => {
            console.log(error);
            setErrorMessage(error.message);
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setIsInvitingOrg(false);
        dispatch(fetchEnd());
      });
  };

  return (
    <>
      <TabbedShowLayout {...restProps} className={classes.tabContainer}>
        <Tab label="Organization Details">
          <Details
            action={action}
            loading={loadingDetails}
            editingData={editingDetails}
            originalData={originalDetails}
            setEditingData={setEditingDetails}
            isVendor={organizationtype === 'Vendor'}
            organizationId={id}
            getData={getDetails}
            pricingTiers={pricingTiers}
            getTags={getTags}
          />
        </Tab>
        {(action === 'CREATE' || organizationtype === 'Vendor') && (
          <Tab label="Products">
            <Products
              action={action}
              organizationId={id}
              editingData={productsData}
              originalData={originalProductsData}
              setEditingData={setProductsData}
              getData={getProducts}
              loading={loadingProducts}
            />
          </Tab>
        )}
        {(action === 'CREATE' || organizationtype === 'Vendor') && (
          <Tab label="Premium features">
            <PremiumFeatures
              action={action}
              organizationId={id}
              originalData={premiumFeatures}
              editingDataMap={editingPremiumFeatures}
              setEditingDataMap={setEditingPremiumFeatures}
              getData={getPremiumFeatures}
              loading={loadingPremiumFeatures}
            />
          </Tab>
        )}
        {action === 'EDIT' && (
          <Tab label="Users">
            <Users
              data={organizationUsers}
              loading={loadingUsers}
              getData={getOrganizationUsers}
              action={action}
            />
          </Tab>
        )}
        {action === 'CREATE' && (
          <Tab label="Owner info">
            <OwnerInfo
              emailAddress={emailAddress}
              setEmailAddress={setEmailAddress}
              fullName={fullName}
              setFullName={setFullName}
            />
          </Tab>
        )}
      </TabbedShowLayout>
      {action === 'CREATE' && (
        <>
          <Divider />
          <Box style={{ backgroundColor: 'white', padding: 10 }} display={'flex'}>
            <Button
              onClick={inviteOrganizationHandler}
              disabled={isInvitingOrg}
              variant={'contained'}
              color="secondary"
            >
              {isInvitingOrg ? <CircularProgress color={'inherit'} size={20} /> : 'Save'}
            </Button>
            {errorMessage && (
              <Alert
                severity="error"
                style={{ marginLeft: 10, padding: '0px 16px' }}
                onClose={() => setErrorMessage('')}
              >
                {errorMessage}
              </Alert>
            )}
          </Box>
        </>
      )}
    </>
  );
};
