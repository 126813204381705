import { useCallback, useEffect, useState } from 'react';
import { fetchEnd, fetchStart } from 'react-admin';
import { fetchApi } from '../utils/api';
import { camelizeKeys } from 'humps';
import { useDispatch } from 'react-redux';

export const initProductsData = {
  products: {},
  columns: {
    unlicensed: {
      id: 'unlicensed',
      title: 'Unlicensed',
      productTypes: [],
    },
    licensed: {
      id: 'licensed',
      title: 'Licensed',
      productTypes: [],
    },
    deactivated: {
      id: 'deactivated',
      title: 'Deactivated',
      productTypes: [],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ['unlicensed', 'licensed', 'deactivated'],
};

export const useCreateOrUpdateProducts = (organizationId) => {
  const [loading, setLoading] = useState(false);
  const [productsData, setProductsData] = useState(initProductsData);
  const [originalProductsData, setOriginalProductsData] = useState(initProductsData);

  const dispatch = useDispatch();

  const parseProductsToShow = (data) => {
    const productsObjValue = (data || []).reduce((result, product) => {
      result[product.productType] = {
        id: product.id,
        productType: product.productType,
        content: product.productType,
      };
      return result;
    }, {});

    const colObjValue = (data || []).reduce(
      (result, product) => {
        switch (product.status) {
          case 0:
            result.licensed.productTypes.push(product.productType);
            break;
          case 1:
            result.deactivated.productTypes.push(product.productType);
            break;
          case 2:
            result.unlicensed.productTypes.push(product.productType);
            break;
          default:
            break;
        }
        return result;
      },
      {
        unlicensed: {
          id: 'unlicensed',
          title: 'Unlicensed',
          productTypes: [],
        },
        licensed: {
          id: 'licensed',
          title: 'Licensed',
          productTypes: [],
        },
        deactivated: {
          id: 'deactivated',
          title: 'Deactivated',
          productTypes: [],
        },
      },
    );

    return {
      products: productsObjValue,
      columns: colObjValue,
    };
  };

  const getProducts = useCallback(() => {
    dispatch(fetchStart());
    setLoading(true);
    fetchApi(`/organizations/${organizationId}/products`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        setProductsData((prevState) => {
          return {
            ...prevState,
            ...parseProductsToShow(camelizeKeys(data)),
          };
        });
        setOriginalProductsData((prevState) => {
          return {
            ...prevState,
            ...parseProductsToShow(camelizeKeys(data)),
          };
        });
      })
      .catch((error) => {
        if (typeof error.json === 'function') {
          error.json().then((error) => {
            console.log(error);
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  }, [dispatch, organizationId]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return {
    productsData,
    setProductsData,
    loading,
    originalProductsData,
    getProducts,
  };
};
