import _ from 'lodash';

export const isAllowedToOverride = (organizationValue, globalValue) => {
  return globalValue === true || globalValue === 0 || globalValue > organizationValue;
};

export const assignOriginalValue = (setting) => {
  const result = [...setting];

  _.forEach(result, function (group) {
    _.forEach(group.items, function (item) {
      item.originalValue = item.value;
    });
  });

  return result;
};

export const getItemByKey = (setting, key) => {
  if (setting?.length) {
    for (const group of setting) {
      for (const item of group.items) {
        if (item.key === key) {
          return item;
        }
      }
    }
  } else {
    return undefined;
  }
};

export const mergeToGlobalSetting = (globalSetting, organizationSetting) => {
  if (!globalSetting) {
    return null;
  }

  const setting = assignOriginalValue(globalSetting);

  if (organizationSetting) {
    const changedItems = getChangedItems(organizationSetting);
    _.forEach(changedItems, function (changedItem) {
      const item = getItemByKey(setting, changedItem.key);
      if (item) {
        item.originalValue = changedItem.value;
        if (isAllowedToOverride(item.value, changedItem.value)) {
          item.value = changedItem.value;
        }
      }
    });
  }

  console.log(JSON.stringify(setting));
  return setting;
};

export const getChangedItems = (setting) => {
  const result = [];
  _.forEach(setting, function (group) {
    _.forEach(group.items, function (item) {
      if (item.value !== item.originalValue) {
        result.push(item);
      }
    });
  });

  return result;
};

export const hasOverriddenValues = (setting) => {
  for (const group of setting) {
    for (const item of group.items) {
      if (item.value !== item.originalValue) {
        return true;
      }
    }
  }
  return false;
};
