import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import DraggableCard from './DraggableCard';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyle = makeStyles({
  container: {
    border: '1px solid lightgrey',
    borderRadius: 2,
    margin: 8,
    width: 340,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 599,
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), ' +
      '0px 2px 2px 0px rgb(0 0 0 / 14%), ' +
      '0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
  title: {
    padding: 8,
    fontWeight: 700,
    fontSize: 18,
    width: '95%',
  },
  productList: {
    padding: 8,
    transition: 'background-color 0.2s ease',
    height: 600,
    overflow: 'auto',
  },
});

const DroppableColumn = (props) => {
  const classes = useStyle();
  return (
    <Box className={classes.container}>
      <Box className={classes.title}>{props.column.title}</Box>
      <Droppable droppableId={props.column.id} isDropDisabled={props.isDropDisabled}>
        {(provided, snapshot) => {
          return (
            <div
              style={{
                backgroundColor: snapshot.isDraggingOver ? '#90ee90' : 'lightgrey',
              }}
              className={classes.productList}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {props.products.map((product, index) => (
                <DraggableCard key={product.productType} product={product} index={index} />
              ))}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </Box>
  );
};

export default DroppableColumn;
