import { camelizeKeys } from 'humps';
import { useCallback, useEffect, useState } from 'react';
import { fetchEnd, fetchStart } from 'react-admin';
import { useDispatch } from 'react-redux';
import { fetchApi } from '../utils/api';

export const useGet360Roles = (userId) => {
  const [loading, setLoading] = useState(false);
  const [newRoles, setNewRoles] = useState(null);

  const dispatch = useDispatch();

  const get360Roles = useCallback(() => {
    dispatch(fetchStart());
    setLoading(true);
    fetchApi(`/users/${userId}/360_roles`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        console.log(1111);
        console.log(data);
        setNewRoles(camelizeKeys(data));
      })
      .catch((error) => {
        if (typeof error.json === 'function') {
          error.json().then((error) => {
            console.log(error);
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  }, [dispatch, userId]);

  useEffect(() => {
    if (!newRoles) {
      get360Roles();
    }
  }, [get360Roles, newRoles]);

  return {
    loading,
    newRoles,
    get360Roles
  };
};
