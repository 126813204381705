import { SelectColumnsButton, useSelectedColumns } from '@react-admin/ra-preferences';
import React, { cloneElement, useEffect, useState } from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  TopToolbar,
  Edit,
  sanitizeListRestProps,
  useListContext,
  DateField,
  CreateButton,
  Create,
  DeleteWithConfirmButton,
} from 'react-admin';
import { PricingTierActions } from './components/pricing-tier/PricingTierToolBar';
import {
  PricingTierCreateTitle,
  PricingTierEditTitle,
} from './components/pricing-tier/PricingTierTitle';
import { PricingTierForm } from './components/pricing-tier/PricingTierForm';
import authProvider from './authProvider';

const PricingTierFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="Search" alwaysOn />
  </Filter>
);

const CustomDeleteButton = ({ record }) => {
  const title = `Delete pricing tier`;
  const content = `Do you want to delete pricing tier named "${record.name}"?`;
  return (
    <DeleteWithConfirmButton
      disabled={record?.organizations_aggregate?.aggregate?.count}
      record={record}
      undoable={true}
      label=""
      confirmTitle={title}
      confirmContent={content}
    />
  );
};
const PricingTierListColumns = {
  id: <TextField source="id" label="Id" />,
  name: <TextField source="name" label="Name" />,
  in_use: (
    <TextField sortable={false} source="organizations_aggregate.aggregate.count" label="In Use" />
  ),
  created_date: <DateField source="created_date" label="Creation Date" locales="de" />,
  created_by: <TextField source="created_by" label="Creator" />,
  updated_date: <DateField source="updated_date" label="Updated Date" locales="de" />,
  updated_by: <TextField source="updated_by" label="Updated By" />,
  actions: <CustomDeleteButton />,
};

const PricingTierListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter } = useListContext();

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      <SelectColumnsButton preference="PricingTier.list.columns" columns={PricingTierListColumns} />
      <CreateButton />
    </TopToolbar>
  );
};

export const PricingTierList = (props) => {
  const columns = useSelectedColumns({
    preferences: 'PricingTier.list.columns',
    columns: PricingTierListColumns,
  });

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (!currentUser) {
      (async function getUser() {
        const data = await authProvider.getIdentity();
        setCurrentUser(data);
      })();
    }
  }, [currentUser]);

  return (
    <List
      actions={<PricingTierListActions />}
      sort={{ field: 'id', order: 'ASC' }}
      perPage={25}
      filters={<PricingTierFilter />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid rowClick="edit">{columns}</Datagrid>
    </List>
  );
};

export const PricingTierCreate = (props) => {
  return (
    <Create title={<PricingTierCreateTitle />} actions={<PricingTierActions />} {...props}>
      <PricingTierForm pricingTierId={0} />
    </Create>
  );
};

export const PricingTierEdit = (props) => {
  return (
    <Edit title={<PricingTierEditTitle />} actions={<PricingTierActions />} {...props}>
      <PricingTierForm pricingTierId={props.id} />
    </Edit>
  );
};
