import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { decamelizeKeys } from 'humps';
import { useEffect, useState } from 'react';
import { fetchEnd, fetchStart, ListButton, TopToolbar } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import authProvider from '../../authProvider';
import { fetchApi } from '../../utils/api';

export const PricingTierToolBar = ({ errorControl, pricingTier }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const payload = decamelizeKeys(pricingTier);

  const [currentUser, setCurrentUser] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  useEffect(() => {
    if (!currentUser) {
      (async function getUser() {
        setCurrentUser(await authProvider.getIdentity());
      })();
    }
  }, [currentUser]);

  useEffect(() => {
    setHasError(Object.values(errorControl).includes(true));
  }, [hasError, errorControl]);

  const savePricingTier = () => {
    if (payload.id) {
      payload.updated_by = currentUser?.fullName;
    } else {
      payload.created_by = currentUser?.fullName;
    }
    setErrorMessage('');
    setIsSaving(true);
    dispatch(fetchStart());
    fetchApi(`/pricing-tiers`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          return Promise.resolve();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        history.push('/pricing_tier');
      })
      .catch((error) => {
        if (typeof error.json === 'function') {
          error.json().then((error) => {
            console.log(error);
            setErrorMessage(error.message);
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setIsSaving(false);
        setOpenConfirmationDialog(false);
        dispatch(fetchEnd());
      });
  };

  return (
    <Box style={{ backgroundColor: 'white', padding: 10 }} display={'flex'}>
      <Button
        onClick={payload.id ? handleOpenConfirmationDialog : savePricingTier}
        disabled={isSaving || hasError}
        variant={'contained'}
        style={{ marginLeft: '95%' }}
        color="secondary"
      >
        {isSaving ? <CircularProgress color={'inherit'} size={20} /> : 'Save'}
      </Button>
      {payload.id && (
        <Dialog
          onClose={handleCloseConfirmationDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={openConfirmationDialog}
        >
          <DialogTitle id="alert-dialog-title">{'Confirmation'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              All changes will all existing tiers in usage.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmationDialog} color="secondary">
              Cancel
            </Button>
            <Button autoFocus onClick={savePricingTier} backgroundColor="primary" color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {errorMessage && (
        <Alert
          severity="error"
          style={{ marginLeft: 10, padding: '0px 16px' }}
          onClose={() => setErrorMessage('')}
        >
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
};

export const PricingTierActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label="Back" />
  </TopToolbar>
);
