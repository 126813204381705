import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyle = makeStyles({
  container: {
    border: '1px solid lightgrey',
    borderRadius: 2,
    padding: 8,
    marginBottom: 8,
    backgroundColor: 'white',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), ' +
      '0px 2px 2px 0px rgb(0 0 0 / 14%), ' +
      '0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
});

const DraggableCard = (props) => {
  const classes = useStyle();
  return (
    <Draggable draggableId={props.product.productType} index={props.index}>
      {(provided, snapshot) => (
        <div
          className={classes.container}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Box>{props.product.content}</Box>
        </div>
      )}
    </Draggable>
  );
};
export default DraggableCard;
