import { useCallback, useEffect, useState } from 'react';
import { fetchEnd, fetchStart } from 'react-admin';
import { fetchApi } from '../utils/api';
import { camelizeKeys } from 'humps';
import { useDispatch } from 'react-redux';

export const useCreateOrUpdatePremiumFeatures = (organizationId) => {
  const [premiumFeatures, setPremiumFeatures] = useState(undefined);
  const [editingPremiumFeatures, setEditingPremiumFeatures] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getPremiumFeatures = useCallback(() => {
    dispatch(fetchStart());
    setLoading(true);
    fetchApi(`/organizations/${organizationId}/features`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        setPremiumFeatures(camelizeKeys(data));
      })
      .catch((error) => {
        error.json().then((error) => {
          console.log(error);
        });
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  }, [dispatch, organizationId]);

  useEffect(() => {
    console.log(editingPremiumFeatures);
  }, [editingPremiumFeatures]);

  useEffect(() => {
    if (!premiumFeatures) {
      getPremiumFeatures();
    }
  }, [getPremiumFeatures, premiumFeatures]);

  return {
    loading,
    premiumFeatures,
    getPremiumFeatures,
    editingPremiumFeatures,
    setEditingPremiumFeatures,
  };
};
