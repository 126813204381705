export const correctPremiumFeatureReqPayload = (editingPremiumFeatures = []) => {
  return editingPremiumFeatures.map((elem) => {
    if (elem.configurations?.l_i_m_i_t) {
      elem.configurations.LIMIT = elem.configurations.l_i_m_i_t;
      delete elem.configurations.l_i_m_i_t;
    }
    return {
      ...elem,
    };
  });
};
