import { camelizeKeys } from 'humps';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { fetchEnd, fetchStart } from 'react-admin';
import { useDispatch } from 'react-redux';
import { fetchApi } from '../utils/api';

export const useCreateOrUpdatePricingTier = (pricingTierId) => {
  const [loading, setLoading] = useState(false);
  const [pricingTier, setPricingTier] = useState({});
  const [editingPricingTier, setEditingPricingTier] = useState({});

  const dispatch = useDispatch();

  const getPricingTier = useCallback(() => {
    dispatch(fetchStart());
    setLoading(true);
    fetchApi(`/pricing-tiers/${pricingTierId}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        setPricingTier(camelizeKeys(data));
      })
      .catch((error) => {
        error.json().then((error) => {
          console.log(error);
        });
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  }, [dispatch, pricingTierId]);

  useEffect(() => {
    if (_.isEmpty(pricingTier)) {
      getPricingTier();
    }
  }, [getPricingTier, pricingTier]);

  return {
    loading,
    pricingTier,
    getPricingTier,
    setPricingTier,
    editingPricingTier,
    setEditingPricingTier,
  };
};

export const useGetPricingTiers = () => {
  const [loading, setLoading] = useState(false);
  const [pricingTiers, setPricingTiers] = useState(null);

  const dispatch = useDispatch();

  const getPricingTiers = useCallback(() => {
    dispatch(fetchStart());
    setLoading(true);
    fetchApi(`/pricing-tiers`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        setPricingTiers(camelizeKeys(data));
      })
      .catch((error) => {
        error.json().then((error) => {
          console.log(error);
        });
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  }, [dispatch]);

  useEffect(() => {
    if (pricingTiers === null) {
      getPricingTiers();
    }
  }, [getPricingTiers, pricingTiers]);

  return {
    loading,
    pricingTiers,
    getPricingTiers,
    setPricingTiers,
  };
};
