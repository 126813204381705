export const parseProductsToSave = (updatedData, originalData) => {
  const { updatedLicensedProductTypes, updatedDeactivatedProductTypes } = getUpdatedProducts(
    updatedData,
    originalData,
  );
  const payload = [];

  payload.push(
    ...updatedLicensedProductTypes.map((productType) => {
      return {
        id: updatedData.products[productType]?.id,
        productType,
        status: 0,
      };
    }),
  );

  payload.push(
    ...updatedDeactivatedProductTypes.map((productType) => {
      return {
        id: updatedData.products[productType]?.id,
        productType,
        status: 1,
      };
    }),
  );

  return payload;
};

export const getUpdatedProducts = (updatedData, originalData) => {
  const newLicensedProductTypes = updatedData?.columns?.licensed?.productTypes;
  const originalLicensedProductTypes = originalData?.columns?.licensed?.productTypes;

  const updatedLicensedProductTypes = newLicensedProductTypes.filter(
    (productType) => originalLicensedProductTypes.indexOf(productType) === -1,
  );

  const newDeactivatedProductTypes = updatedData?.columns?.deactivated?.productTypes;
  const originalDeactivatedProductTypes = originalData?.columns?.deactivated?.productTypes;

  const updatedDeactivatedProductTypes = newDeactivatedProductTypes.filter(
    (productType) => originalDeactivatedProductTypes.indexOf(productType) === -1,
  );

  return {
    updatedLicensedProductTypes,
    updatedDeactivatedProductTypes,
  };
};
