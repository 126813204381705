import { useState } from 'react';

export const useCreateOwnerInfo = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [fullName, setFullName] = useState('');

  return {
    emailAddress,
    setEmailAddress,
    fullName,
    setFullName,
  };
};
