import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { decamelizeKeys } from 'humps';
import { fetchEnd, fetchStart } from 'ra-core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchApi } from '../../utils/api';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { PricingTierContent } from './PricingTierContent';
import { mergeToGlobalSetting } from '../../utils/PricingTierUtils';
import Tags from '../Tags';
import { TagType } from '../../enum/tag-type';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    width: '50%',
    '& > div:not(:first-child)': {
      marginTop: 30,
    },
  },
});

const TypeOptions = [
  { id: 'Vendor', name: 'Vendor' },
  { id: 'Partner', name: 'Partner' },
];

const Details = ({
  loading,
  isVendor,
  organizationId,
  getData,
  action,
  editingData,
  originalData,
  setEditingData,
  pricingTiers,
  getTags,
  newPricingTierEnabled,
}) => {
  const classes = useStyles();
  const [isDisableSaveBtn, setIsDisableSaveButton] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowRevokeInvitationModal, setIsShowRevokeInvitationModal] = useState(false);
  const [isRevokingInvitation, setIsRevokingInvitation] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const saveDetails = useCallback(() => {
    dispatch(fetchStart());
    setIsSaving(true);
    fetchApi(`/organizations/${organizationId}/details`, {
      method: 'POST',
      body: JSON.stringify(decamelizeKeys(editingData)),
    })
      .then((response) => {
        if (response.ok) {
          return Promise.resolve();
        } else {
          return Promise.reject(response);
        }
      })
      .then(() => {
        setIsDisableSaveButton(true);
        getData();
      })
      .catch((error) => {
        if (typeof error.json === 'function') {
          error.json().then((error) => {
            console.log(error);
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setIsSaving(false);
        dispatch(fetchEnd());
      });
  }, [getData, editingData, dispatch, organizationId]);

  const handleShowRevokeInvitationModal = useCallback(() => {
    setIsShowRevokeInvitationModal(!isShowRevokeInvitationModal);
    setErrorMessage('');
  }, [isShowRevokeInvitationModal]);

  const handleRevokeInvitation = async () => {
    setIsRevokingInvitation(true);
    dispatch(fetchStart());
    fetchApi(`/organizations/${organizationId}`, {
      method: 'DELETE',
    })
      .then((res) => {
        if (res.ok) {
          return Promise.resolve();
        } else {
          return Promise.reject(res);
        }
      })
      .then(() => {
        handleShowRevokeInvitationModal();
        history.push('/organization');
      })
      .catch((error) => {
        if (typeof error.json === 'function') {
          error.json().then((error) => {
            console.log(error);
            setErrorMessage(error.message);
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setIsRevokingInvitation(false);
        dispatch(fetchEnd());
      });
  };

  useEffect(() => {
    setIsDisableSaveButton(_.isEqual(originalData, editingData));
  }, [originalData, editingData]);

  const selectTypeHandler = (event) => {
    setEditingData((prevState) => ({
      ...prevState,
      type: event.target.value,
    }));
  };

  const changeNameHandler = (event) => {
    setEditingData((prevState) => ({
      ...prevState,
      name: event.target.value,
    }));
  };

  const selectPricingTierV2Handler = (event) => {
    const newPricingTierV2 = { ..._.find(pricingTiers, { id: +event.target.value }) };
    setEditingData((prevState) => ({
      ...prevState,
      pricingTierV2: {
        ...newPricingTierV2,
        setting: mergeToGlobalSetting(
          newPricingTierV2.setting,
          originalData.pricingTierV2?.setting,
        ),
      },
    }));
  };

  const changeSymbolHandler = (event) => {
    setEditingData((prevState) => ({
      ...prevState,
      symbol: event.target.value,
    }));
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginTop: '10%',
          marginBottom: '10%',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const getAdditionalUser = () => {
    const freeUser = editingData.pricingTierV2?.setting
      ?.find((p) => p.key === 'user')
      ?.items?.find((p) => p.key === 'free_user')?.value;
    if (+freeUser === 0) {
      return 0
    }
    
    return editingData?.userDataPoint?.maxUser - freeUser > 0
      ? editingData.userDataPoint.maxUser - freeUser
      : 0;
  };

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" className={classes.container}>
          {action === 'EDIT' && (
            <TextField disabled label="ID" defaultValue={originalData.id} variant="outlined" />
          )}
          <TextField
            label="Name*"
            defaultValue={editingData.name}
            variant="outlined"
            onChange={changeNameHandler}
          />
          {(action === 'CREATE' || isVendor) && (
            <TextField
              label="Symbol*"
              defaultValue={editingData.symbol}
              variant="outlined"
              onChange={changeSymbolHandler}
              disabled={action === 'EDIT'}
            />
          )}
          {(action === 'CREATE' || isVendor) && (
            <FormControl variant="outlined">
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                value={editingData.type || 'Vendor'}
                onChange={selectTypeHandler}
                disabled
                labelId="type-label"
                id="type"
                label="Type"
              >
                {TypeOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {isVendor && (
            <TextField
              label="Status"
              defaultValue={editingData.status}
              variant="outlined"
              disabled
            />
          )}
          {(action === 'CREATE' || isVendor) && (
            <>
              <FormControl variant="outlined">
                <InputLabel id="pricing-tier-v2">Pricing Tier V2</InputLabel>
                <Select
                  value={editingData.pricingTierV2?.id || 0}
                  onChange={selectPricingTierV2Handler}
                  labelId="pricing-tier-v2-label"
                  id="pricing-tier-v2"
                  label="Pricing Tier V2"
                >
                  {pricingTiers?.map((pricingTier) => (
                    <MenuItem key={pricingTier.id} value={pricingTier.id}>
                      {pricingTier.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {editingData.pricingTierV2 && (
                <>
                  <PricingTierContent
                    action={action}
                    originalData={originalData}
                    editingData={editingData}
                    setEditingData={setEditingData}
                    setIsDisableSaveButton={setIsDisableSaveButton}
                    setErrorMessage={setErrorMessage}
                  />
                </>
              )}
            </>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.container}
          style={{ marginLeft: 20 }}
        >
          {action === 'EDIT' && (
            <TextField
              label="Actual users"
              defaultValue={editingData?.userDataPoint?.actualUser}
              variant="outlined"
              disabled
            />
          )}
          {action === 'EDIT' && (
            <TextField
              label="Max user last month"
              defaultValue={editingData?.userDataPoint?.maxUser}
              variant="outlined"
              disabled
            />
          )}
          {action === 'EDIT' && (
            <TextField
              label="Actual billable users"
              defaultValue={editingData?.userDataPoint?.actualBillableUser}
              variant="outlined"
              disabled
            />
          )}
          {action === 'EDIT' && (
            <TextField
              label="Max billable users last month"
              defaultValue={editingData?.userDataPoint?.maxBillableUser}
              variant="outlined"
              disabled
            />
          )}

          {action === 'EDIT' && editingData.pricingTierV2 && (
            <TextField
              label="Additional paid user"
              defaultValue={getAdditionalUser()}
              variant="outlined"
              disabled
            />
          )}
          {action === 'EDIT' && isVendor && (
            <>
              <TextField
                disabled
                label="Actual customer data point"
                defaultValue={originalData.customerDataPoint?.actualTotal}
                variant="outlined"
              />
              <TextField
                disabled
                label="Max customer data point last month"
                defaultValue={originalData.customerDataPoint?.maxCustomer}
                variant="outlined"
              />
            </>
          )}
          <Tags
            loading={loading}
            tagType={TagType.ORGANIZATION_TAG}
            tagList={editingData.tags || []}
            setIsDisableSaveButton={setIsDisableSaveButton}
            getData={getData}
            getTags={getTags}
            organizationId={organizationId}
          />
        </Box>
      </Box>
      {action === 'EDIT' && (
        <Button
          onClick={saveDetails}
          disabled={isSaving || loading || isDisableSaveBtn}
          color="secondary"
          variant="contained"
          style={{ textTransform: 'none', marginTop: 20 }}
        >
          {isSaving ? <CircularProgress size={20} color={'inherit'} /> : 'Save'}
        </Button>
      )}
      {editingData.status === 'Pending' && (
        <>
          <Divider style={{ marginTop: 10 }} />
          <Button
            disabled={false}
            disableElevation
            style={{ marginTop: 10, textTransform: 'none' }}
            variant="outlined"
            color="secondary"
            onClick={() => {
              handleShowRevokeInvitationModal();
            }}
          >
            Revoke invitation
          </Button>
          <Dialog open={isShowRevokeInvitationModal} onClose={handleShowRevokeInvitationModal}>
            <DialogTitle>{'Revoke Invitation?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to revoke invitation?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleShowRevokeInvitationModal}
                color="default"
                variant={'outlined'}
                style={{ textTransform: 'none', width: 100, height: 36 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleRevokeInvitation}
                color="secondary"
                variant={'contained'}
                style={{ marginLeft: 20, width: 100, height: 36, textTransform: 'none' }}
                disabled={isRevokingInvitation}
              >
                {isRevokingInvitation ? <CircularProgress color={'inherit'} size={20} /> : 'Yes'}
              </Button>
            </DialogActions>
            {errorMessage && (
              <Alert severity="error" style={{ margin: 10 }} onClose={() => setErrorMessage('')}>
                {errorMessage}
              </Alert>
            )}
          </Dialog>
        </>
      )}
    </>
  );
};

export default Details;
