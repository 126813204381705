import awsExports from './aws-exports';
import config from './config';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production

const awsConfig = {
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: isLocalhost
      ? 'http://localhost:3002/'
      : awsExports.oauth.redirectSignIn.split(',')[0],
    redirectSignOut: isLocalhost
      ? 'http://localhost:3002/'
      : awsExports.oauth.redirectSignOut.split(',')[0],
  },
  API: {
    endpoints: [
      ...(awsExports.API?.endpoints || []),
      {
        name: 'internal-auth',
        endpoint: config.API_INTERNAL_AUTH,
      },
    ],
  },
  Auth: {
    ...awsExports.Auth,
    authenticationFlowType: null,
  },
};

export default awsConfig;
