import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  TextField,
  Box,
  Button,
} from '@material-ui/core';
import _ from 'lodash';
import { FieldType } from '../../enum/field-type';
import React, { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { hasOverriddenValues } from '../../utils/PricingTierUtils';

const useStyles = makeStyles({
  numberInput: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 'auto',
  },
  numberInputRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
  actionButton: {
    display: 'none',
  },
  checkBoxLabel: { font: 14 },
  input: {
    '&::placeholder': {
      color: '#323232',
      opacity: 1,
    },
  },
  notchedOutlineOverridden: {
    borderColor: '#4caf50 !important',
  },
  notchedOutline: {
    borderColor: '#283593 !important',
  },
  cssLabelOverridden: {
    color: '#4caf50',
    '&.Mui-focused': {
      color: '#4caf50',
    },
  },
});

export const PricingTierContent = ({
  action,
  originalData,
  editingData,
  setEditingData,
  setIsDisableSaveButton,
  setErrorMessage,
}) => {
  const classes = useStyles();
  const [pricingTier, setPricingTier] = useState(editingData.pricingTierV2);
  const [collapseStatusControl, settingCollapseStatusControl] = useState({});
  const [errorControl, setErrorControl] = useState({});
  const [collapsePricingTier, setCollapsePricingTier] = useState(false);
  useEffect(() => {
    if (_.isEmpty(collapseStatusControl)) {
      const initCollapseStatusControl = {};
      pricingTier?.setting?.forEach((pricingTierGroup) => {
        initCollapseStatusControl[pricingTierGroup.key] = true;
      });

      settingCollapseStatusControl(initCollapseStatusControl);
    }
  }, [collapseStatusControl, pricingTier]);

  useEffect(() => {
    setPricingTier(editingData.pricingTierV2);
  }, [setPricingTier, editingData]);

  useEffect(() => {
    if (_.isEmpty(errorControl)) {
      const initErrorControl = {};
      pricingTier?.setting?.forEach((pricingTierGroup) => {
        pricingTierGroup?.items?.forEach((item) => {
          initErrorControl[item.key] = false;
        });
      });
      setErrorControl(initErrorControl);
    }
  }, [errorControl, pricingTier]);

  const handleClick = (key) => () => {
    settingCollapseStatusControl({ ...collapseStatusControl, [key]: !collapseStatusControl[key] });
  };

  const handleCollapsePricingTier = () => {
    setCollapsePricingTier(!collapsePricingTier);
  };

  useEffect(() => {
    setIsDisableSaveButton(_.includes(Object.values(errorControl), true));
  }, [setIsDisableSaveButton, errorControl]);

  useEffect(() => {
    setEditingData((prevState) => ({
      ...prevState,
      pricingTierV2: pricingTier,
    }));
  }, [setEditingData, pricingTier]);

  const changeValueHandler = (firstLevelKey, groupKey, itemKey) => (event, checked) => {
    setPricingTier((prevState) => {
      const data = _.cloneDeep(prevState);
      if (firstLevelKey) {
        let firstLevel = data[firstLevelKey];
        if (groupKey) {
          let secondLevel = _.find(firstLevel, { key: groupKey });
          if (itemKey) {
            let thirdLevel = _.find(secondLevel.items, { key: itemKey });
            if (thirdLevel.type === FieldType.CHECKBOX) {
              thirdLevel.value = checked;
              if (thirdLevel.isParent) {
                _.forEach(_.filter(secondLevel.items, { parentKey: itemKey }), function (item) {
                  item.value = checked;
                });
              }

              if (thirdLevel.parentKey) {
                const parent = _.find(secondLevel.items, { key: thirdLevel.parentKey });
                if (parent) {
                  if (
                    (checked && !parent.value) || //Child check > parent check
                    (!checked && !_.some(secondLevel.items, { parentKey: parent.key, value: true })) //Uncheck child > uncheck parent if no child check
                  ) {
                    parent.value = checked;
                  }
                }
              }
            } else {
              let value = event.target.value;
              if (!value || value[value.length - 1].match('[0-9]')) {
                thirdLevel.value = event.target.value || 0;
              }
            }
          }
        } else {
          data[firstLevelKey] = event.target.value;
        }
      }
      return data;
    });
  };

  const getHelpText = (key) => {
    if (action !== 'EDIT') {
      return 'This field is required.';
    }

    switch (key) {
      case 'free_user':
        return 'Free user cannot be less than current users.';
      default:
        return 'This field is required.';
    }
  };

  return (
    <>
      <Box style={{ display: 'flex', marginTop: 0 }}>
        <Button
          color="primary"
          style={{ textTransform: 'none', width: 'auto', marginLeft: 'auto' }}
          className={classes.button}
          startIcon={collapsePricingTier ? <ExpandLess /> : <ExpandMore />}
          onClick={handleCollapsePricingTier}
        >
          {collapsePricingTier ? 'Hide pricing details' : 'Show pricing details'}
        </Button>
      </Box>

      <Collapse in={collapsePricingTier} timeout="auto" unmountOnExit>
        <Box boxShadow={1} style={{ border: '0.5px solid #c4c4c4', borderRadius: 4 }}>
          {hasOverriddenValues(pricingTier?.setting) && (
            <Alert severity="info" color="success" style={{ margin: 8 }}>
              Green settings are customized for this organization.
            </Alert>
          )}
          <List>
            {pricingTier?.setting?.map((pricingTierGroup) => {
              return (
                <>
                  <ListItem
                    key={pricingTierGroup.key}
                    button
                    onClick={handleClick(pricingTierGroup.key)}
                    style={{ marginTop: 10, width: '100%' }}
                  >
                    <ListItemText
                      primary={<Typography variant="h6">{pricingTierGroup.name}</Typography>}
                    />
                    {collapseStatusControl[pricingTierGroup.key] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={collapseStatusControl[pricingTierGroup.key]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <FormControl style={{ width: '90%', maxWidth: 600 }}>
                      <FormGroup>
                        {pricingTierGroup?.items.map((item) => {
                          switch (item.type) {
                            case FieldType.CHECKBOX:
                              return (
                                <>
                                  <FormControlLabel
                                    style={{ marginLeft: item.parentKey ? 20 : 4 }}
                                    control={
                                      <Checkbox
                                        checked={item.value}
                                        onChange={changeValueHandler(
                                          'setting',
                                          pricingTierGroup.key,
                                          item.key,
                                        )}
                                        name={item.key}
                                        classes={{
                                          label: classes.checkBoxLabel,
                                        }}
                                        style={{
                                          color:
                                            item.value !== item.originalValue
                                              ? '#4caf50'
                                              : '#283593',
                                        }}
                                      />
                                    }
                                    label={<Typography variant="subtitle2">{item.name}</Typography>}
                                  />
                                </>
                              );
                            case FieldType.NUMBER_FIELD:
                              return (
                                <>
                                  <Box className={classes.numberInputRow}>
                                    <Typography variant="subtitle2" style={{ marginLeft: 16 }}>
                                      {item.name}
                                    </Typography>
                                    <Box className={classes.numberInput}>
                                      <TextField
                                        onChange={changeValueHandler(
                                          'setting',
                                          pricingTierGroup.key,
                                          item.key,
                                        )}
                                        variant="outlined"
                                        size="small"
                                        error={errorControl[item.key]}
                                        helperText={
                                          errorControl[item.key] ? getHelpText(item.key) : ''
                                        }
                                        inputProps={{ maxLength: 4 }}
                                        InputProps={{
                                          classes: {
                                            input: classes.input,
                                            notchedOutline:
                                              item.value === item.originalValue
                                                ? classes.notchedOutline
                                                : classes.notchedOutlineOverridden,
                                          },
                                        }}
                                        placeholder={
                                          item.key !== 'free_user' &&
                                          (!item.value || item.value <= 0)
                                            ? 'Limitless'
                                            : 0
                                        }
                                        value={item.value > 0 ? item.value : ''}
                                        style={{ width: 250 }}
                                        label={
                                          item.value !== item.originalValue
                                            ? `Global setting: 
                                        ${
                                          item.key !== 'free_user' &&
                                          (!item.originalValue || item.originalValue < 0)
                                            ? 'Limitless'
                                            : item.originalValue
                                        }
                                        `
                                            : ''
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                          classes:
                                            item.value !== item.originalValue
                                              ? {
                                                  root: classes.cssLabelOverridden,
                                                }
                                              : '',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </>
                              );
                            default:
                              return null;
                          }
                        })}
                      </FormGroup>
                    </FormControl>
                  </Collapse>
                </>
              );
            })}
          </List>
        </Box>
      </Collapse>
    </>
  );
};
