import { LocalStorage } from 'ttl-localstorage';
import config from '../config';

import { API } from 'aws-amplify';

const TOKEN_REFRESH_TTL = 900;

export const fetchApi = async (path, init) =>
  fetch(`${config.API_SLS_ADMIN_PANEL}${path}`, {
    ...init,
    headers: { authorization: `Bearer ${await getToken()}`, ...init?.headers },
  });

export const getToken = async () => {
  let token = await LocalStorage.get('token');

  if (!token) {
    const res = await API.get('internal-auth', '/auth');
    token = res.token;
    LocalStorage.put('token', token, TOKEN_REFRESH_TTL);
  }

  return token;
};
