import { useCallback, useEffect, useState } from 'react';
import { fetchEnd, fetchStart } from 'react-admin';
import { camelizeKeys } from 'humps';
import { useDispatch } from 'react-redux';
import { fetchApi } from '../utils/api';

export const useGetUsers = (organizationId) => {
  const [loading, setLoading] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState(undefined);
  const dispatch = useDispatch();

  const getOrganizationUsers = useCallback(() => {
    dispatch(fetchStart());
    setLoading(true);
    fetchApi(`/organizations/${organizationId}/users`, { method: 'GET' })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        setOrganizationUsers(camelizeKeys(data));
      })
      .catch((error) => {
        error.json().then((error) => {
          console.log(error);
        });
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (!organizationUsers) {
      getOrganizationUsers();
    }
  }, [getOrganizationUsers, organizationUsers]);

  return {
    loading,
    organizationUsers,
    getOrganizationUsers,
  };
};
