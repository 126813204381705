import React from 'react';
import { useHasuraProvider } from './useHasuraProvider';
import { Admin } from '@react-admin/ra-enterprise';
import { Auth } from 'aws-amplify';
import { Loading } from 'react-admin';
import { withAuthenticator } from '@aws-amplify/ui-react';
import AuthProvider from './authProvider';
import './App.css';

const App = () => {
  const { dataProvider, resources } = useHasuraProvider();

  return (
    <Admin
      title="epilot admin"
      dataProvider={dataProvider}
      authProvider={AuthProvider}
      loginPage={() => {
        Auth.federatedSignIn();
        return <Loading />;
      }}
    >
      {resources}
    </Admin>
  );
};

export default withAuthenticator(App, { hideDefault: true });
