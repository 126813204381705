const config = {
  API_INTERNAL_AUTH:
    process.env.REACT_APP_API_INTERNAL_AUTH ||
    'https://h2d2syd0a4.execute-api.eu-central-1.amazonaws.com/dev/v1/internal-auth',
  API_HASURA_GRAPHQL:
    process.env.REACT_APP_API_HASURA_GRAPHQL || 'http://192.168.78.28:8080/v1/graphql',
  API_SLS_ADMIN_PANEL:
    process.env.REACT_APP_API_SLS_ADMIN_PANEL || 'https://vb3whmbvc8.execute-api.eu-central-1.amazonaws.com/dev/v1/admin-panel',
  API_IVY_ADMIN_PANEL:
    process.env.REACT_APP_API_IVY_ADMIN_PANEL || 'https://api.dev.epilot.cloud/v2/admin-panel',
  STAGE: process.env.REACT_APP_STAGE || 'dev',
  GITLAB_FEATURE_FLAG_API_URL: 'https://gitlab.com/api/v4/feature_flags/unleash/25986377',
  GITLAB_FEATURE_INSTANCE_ID: 'HYzftNpzcryeui4yxaUB',
  NEW_PRICING_TIER_FEATURE_NAME: `${process.env.REACT_APP_STAGE || 'dev'}_new_pricing_tier`,
};

export default config;
