import {
  TextField,
  Chip,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';
import { fetchApi } from '../utils/api';
import { fetchEnd, fetchStart } from 'ra-core';
import _ from 'lodash';

const Tags = ({
  loading,
  tagType,
  tagList,
  getTags,
  setIsDisableSaveButton,
  getData,
  organizationId,
  style,
}) => {
  const dispatch = useDispatch();
  const [isShowTagModal, setIsShowTagModal] = useState(false);
  const [isAddingOrUpdatingTag, setIsAddingOrUpdatingTag] = useState(false);
  const [isUpdateTag, setIsUpdateTag] = useState(false);
  const [newTag, setNewTag] = useState({
    id: null,
    value: '',
    type: tagType,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const onTagChange = (event, values) => {
    tagList.forEach((tag) => {
      if ([values].flat().filter((value) => value.tagId === tag.tagId).length > 0) {
        tag.isAdded = true;
      } else {
        tag.isAdded = false;
      }
    });
    setIsDisableSaveButton(false);
  };
  const handleShowAddTagModal = useCallback(() => {
    setIsShowTagModal(!isShowTagModal);
    setErrorMessage('');
    setNewTag({ id: null, value: '', type: tagType });
    setIsUpdateTag(false);
  }, [isShowTagModal, tagType]);

  const handleAddOrUpdateTag = async () => {
    setIsAddingOrUpdatingTag(true);
    dispatch(fetchStart());
    fetchApi(`/tags`, {
      method: 'POST',
      body: JSON.stringify(newTag),
    })
      .then((res) => {
        if (res.ok) {
          return Promise.resolve();
        } else {
          return Promise.reject(res);
        }
      })
      .then(() => {
        handleShowAddTagModal();
        switch (tagType) {
          case 0:
            if (organizationId) {
              getData();
            } else {
              getTags();
            }
            break;
          case 1:
            getTags();
            break;

          default:
            break;
        }
      })
      .catch((error) => {
        if (typeof error.json === 'function') {
          error.json().then((error) => {
            console.log(error);
            setErrorMessage(error.message);
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setIsAddingOrUpdatingTag(false);
        dispatch(fetchEnd());
      });
  };

  const changeTagHandler = (event) => {
    setNewTag((prevState) => ({
      ...prevState,
      value: event.target.value,
    }));
  };

  const validateTag = (event) => {
    if (_.isEmpty(event.target.value)) {
      setErrorMessage('Tag name is required');
    } else {
      setErrorMessage('');
    }
  };

  const handleUpdateTag = (tag) => {
    setIsUpdateTag(true);
    setNewTag({
      ...newTag,
      id: tag.tagId,
      value: tag.tagValue,
    });
    setIsShowTagModal(true);
  };

  const getDefaultValue = () => {
    if (!tagList || !tagList || tagList.length === 0) {
    }
    return [tagList].flat().filter((tag) => tag && tag.isAdded);
  };
  
  return (
    <>
      <Autocomplete
        disabled={loading}
        variant="outlined"
        style={style}
        multiple
        id="size-medium-outlined-multi"
        size="medium"
        disableCloseOnSelect="true"
        onChange={onTagChange}
        defaultValue={getDefaultValue()}
        options={tagList || []}
        getOptionLabel={(option) => option.tagValue}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.tagValue}
              size="medium"
              onClick={() => handleUpdateTag(option)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => <TextField {...params} variant="outlined" label="Tags" />}
      />
      <Link
        variant="outlined"
        style={{ marginTop: 10, textTransform: 'none', cursor: 'pointer' }}
        onClick={() => {
          handleShowAddTagModal();
        }}
      >
        + Add new tag
      </Link>
      <Dialog open={isShowTagModal} onClose={handleShowAddTagModal}>
        <DialogTitle>{isUpdateTag ? 'Update tag' : 'Create a new tag'}</DialogTitle>
        <DialogContent>
          <TextField
            id="standard-basic"
            label="Tag name"
            value={newTag.value}
            onChange={changeTagHandler}
            onBlur={validateTag}
            error={errorMessage.length !== 0}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleShowAddTagModal}
            color="default"
            variant={'outlined'}
            style={{ textTransform: 'none', width: 100, height: 36 }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddOrUpdateTag}
            color="secondary"
            variant={'contained'}
            style={{ marginLeft: 20, width: 100, height: 36, textTransform: 'none' }}
            disabled={isAddingOrUpdatingTag}
          >
            {isAddingOrUpdatingTag ? (
              <CircularProgress color={'inherit'} size={20} />
            ) : isUpdateTag ? (
              'Update'
            ) : (
              'Create'
            )}
          </Button>
        </DialogActions>
        {errorMessage && (
          <Alert severity="error" style={{ margin: 10 }} onClose={() => setErrorMessage('')}>
            {errorMessage}
          </Alert>
        )}
      </Dialog>
    </>
  );
};

export default Tags;
