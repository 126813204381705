import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  TextField,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { SimpleForm } from 'react-admin';
import { PricingTierToolBar } from './PricingTierToolBar';
import { FieldType } from '../../enum/field-type';
import { useCreateOrUpdatePricingTier } from '../../hooks/useCreateOrUpdatePricingTier';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles({
  numberInput: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 'auto',
  },
  numberInputRow: {
    width: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
  actionButton: {
    display: 'none',
  },
  checkBoxLabel: { font: 14 },
  input: {
    '&::placeholder': {
      color: '#323232',
      opacity: 1,
    },
  },
});

export const PricingTierForm = ({ pricingTierId }) => {
  const classes = useStyles();
  const { loading, pricingTier, setPricingTier } = useCreateOrUpdatePricingTier(pricingTierId);
  const [collapseStatusControl, settingCollapseStatusControl] = useState({});
  const [errorControl, setErrorControl] = useState({});
  useEffect(() => {
    if (_.isEmpty(collapseStatusControl)) {
      const initCollapseStatusControl = {};
      pricingTier?.setting?.forEach((pricingTierGroup) => {
        initCollapseStatusControl[pricingTierGroup.key] = true;
      });

      settingCollapseStatusControl(initCollapseStatusControl);
    }
  }, [collapseStatusControl, pricingTier]);

  useEffect(() => {
    if (_.isEmpty(errorControl)) {
      const initErrorControl = {};
      pricingTier?.setting?.forEach((pricingTierGroup) => {
        pricingTierGroup?.items?.forEach((item) => {
          initErrorControl[item.key] = false;
        });
      });
      initErrorControl['pricing_tier_name'] = false;
      setErrorControl(initErrorControl);
    }
  }, [errorControl, pricingTier]);

  const handleClick = (key) => () => {
    settingCollapseStatusControl({ ...collapseStatusControl, [key]: !collapseStatusControl[key] });
  };

  const validateRequiredField = (key) => (event) => {
    if (key === 'pricing_tier_name') {
      setErrorControl({ ...errorControl, [key]: _.isEmpty(event.target.value) });
    }
  };

  const changeValueHandler = (firstLevelKey, groupKey, itemKey) => (event, checked) => {
    setPricingTier((prevState) => {
      const data = _.cloneDeep(prevState);
      if (firstLevelKey) {
        let firstLevel = data[firstLevelKey];
        if (groupKey) {
          let secondLevel = _.find(firstLevel, { key: groupKey });
          if (itemKey) {
            let thirdLevel = _.find(secondLevel.items, { key: itemKey });
            if (thirdLevel.type === FieldType.CHECKBOX) {
              thirdLevel.value = checked;
              if (thirdLevel.isParent) {
                _.forEach(_.filter(secondLevel.items, { parentKey: itemKey }), function (item) {
                  item.value = checked;
                });
              }

              if (thirdLevel.parentKey) {
                const parent = _.find(secondLevel.items, { key: thirdLevel.parentKey });
                if (parent) {
                  if (
                    (checked && !parent.value) || //Child check > parent check
                    (!checked && !_.some(secondLevel.items, { parentKey: parent.key, value: true })) //Uncheck child > uncheck parent if no child check
                  ) {
                    parent.value = checked;
                  }
                }
              }
            } else {
              let value = event.target.value;
              if (!value || value[value.length - 1].match('[0-9]')) {
                thirdLevel.value = event.target.value || 0;
              }
            }
          }
        } else {
          data[firstLevelKey] = event.target.value;
        }
      }
      return data;
    });
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginTop: '10%',
          marginBottom: '10%',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <SimpleForm
      style={{ width: 800 }}
      toolbar={<PricingTierToolBar errorControl={errorControl} pricingTier={pricingTier} />}
    >
      <TextField
        autoFocus
        variant="outlined"
        size="small"
        value={pricingTier.name}
        style={{ marginLeft: 9 }}
        addLabel={false}
        error={errorControl['pricing_tier_name']}
        onBlur={validateRequiredField('pricing_tier_name')}
        helperText={errorControl['pricing_tier_name'] ? 'This field is required.' : ''}
        onChange={changeValueHandler('name')}
      />
      <List style={{ width: 800 }}>
        {pricingTier?.setting?.map((pricingTierGroup) => {
          return (
            <>
              <ListItem
                key={pricingTierGroup.key}
                button
                onClick={handleClick(pricingTierGroup.key)}
                style={{ width: 800, marginTop: 10 }}
              >
                <ListItemText
                  primary={<Typography variant="h6">{pricingTierGroup.name}</Typography>}
                />
                {collapseStatusControl[pricingTierGroup.key] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={collapseStatusControl[pricingTierGroup.key]}
                timeout="auto"
                unmountOnExit
              >
                <FormControl>
                  <FormGroup>
                    {pricingTierGroup?.items.map((item) => {
                      switch (item.type) {
                        case FieldType.CHECKBOX:
                          return (
                            <>
                              <FormControlLabel
                                style={{ marginLeft: item.parentKey ? 20 : 4 }}
                                control={
                                  <Checkbox
                                    checked={item.value}
                                    onChange={changeValueHandler(
                                      'setting',
                                      pricingTierGroup.key,
                                      item.key,
                                    )}
                                    name={item.key}
                                    classes={{ label: classes.checkBoxLabel }}
                                  />
                                }
                                label={<Typography variant="subtitle2">{item.name}</Typography>}
                              />
                            </>
                          );
                        case FieldType.NUMBER_FIELD:
                          return (
                            <>
                              <Box className={classes.numberInputRow}>
                                <Typography variant="subtitle2" style={{ marginLeft: 16 }}>
                                  {item.name}
                                </Typography>
                                <Box className={classes.numberInput}>
                                  <TextField
                                    onChange={changeValueHandler(
                                      'setting',
                                      pricingTierGroup.key,
                                      item.key,
                                    )}
                                    variant="outlined"
                                    size="small"
                                    error={errorControl[item.key]}
                                    onBlur={validateRequiredField(item.key)}
                                    helperText={
                                      errorControl[item.key] ? 'This field is required.' : ''
                                    }
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ classes: { input: classes.input } }}
                                    value={item.value > 0 ? item.value : ''}
                                    placeholder={
                                      item.key !== 'free_user' && (!item.value || item.value <= 0)
                                        ? 'Limitless'
                                        : 0
                                    }
                                    style={{ width: 250 }}
                                  />
                                </Box>
                              </Box>
                            </>
                          );
                        default:
                          return null;
                      }
                    })}
                  </FormGroup>
                </FormControl>
              </Collapse>
            </>
          );
        })}
      </List>
    </SimpleForm>
  );
};
